import { Disclosure, Menu } from "@headlessui/react";
import { AcademicCapIcon, Bars4Icon, PowerIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { useCourses } from "../../hooks/useCourses";
import { useTests } from "../../hooks/useTests";
import { useUser } from "../../hooks/useUser";
import { routesPath } from "../../interfaces/portalPages";
import { getRoutePath } from "../../utils/portalPages";
import { useRecoilState } from "recoil";
import { meetingState } from "../../state/meeting";
import { useSDKMeeting } from "../../context/SdkMeetingContext";
import { IStudentData, getUser } from "../../api/user";

/**
 * @description This component contains the portal header,
 * it has some code commented because the mobile integration is not defined yet
 * or the code could be helpful as example for future improvements
 */
export default function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const { logout } = useUser();
  const { getCourses, selectedCourse, reset: resetCourses } = useCourses();
  const [isCoursesPage, setIsCoursesPage] = useState<boolean>(true);
  const { isReviewTestEnabled } = useTests();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const sessionId = meeting?.session?.sessionId;
  const { sdkMeeting } = useSDKMeeting();
  const [username, setUsername] = useState("");

  useEffect(() => {
    const match =
      matchRoutes(
        [
          {
            path: routesPath["courses"],
          },
        ],
        location
      )?.length ?? 0;
    setIsCoursesPage(match > 0);
  }, [location]);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    getUser().then((studenInfo: IStudentData) => {
      setUsername(`${studenInfo.firstname} ${studenInfo.lastname1} ${studenInfo.lastname2}`);
    });
  }, []);

  const logoutAction = async () => {
    if (sdkMeeting) {
      sdkMeeting.leave();
    }
    resetCourses();
    await logout();
  };

  return (
    <Disclosure as="nav" className="fixed top-0 left-0 w-full z-50 bg-white top-0 w-full border-b border-gray-light">
      {({ open, close }) => (
        <>
          <div className="2xl:max-w-[1440px] w-full py-2 px-6 md:px-9 mx-auto">
            <div className="flex items-center justify-between">
              <div className="flex align-middle items-center grow">
                <Link to={getRoutePath("courses")} className="h-full shrink-0">
                  <img src="/logo_header.png" alt="Torcal" />
                </Link>

                {!isCoursesPage && selectedCourse ? (
                  <div className="hidden md:flex w-full">
                    <div className="ml-10 flex items-baseline space-x-4 justify-center w-full">
                      <Link
                        key="home"
                        to={getRoutePath("course", {
                          courseId: `${selectedCourse.id}`,
                        })}
                        className={classNames("rounded-md text-sm font-light hover:bg-green-200 p-2", {
                          "text-green-torcal ": matchRoutes(
                            [
                              {
                                path: routesPath["course"],
                              },
                            ],
                            location
                          )?.length,
                        })}
                      >
                        {t("Header.home")}
                      </Link>
                      <Link
                        key="lecciones"
                        to={selectedCourse?.hasLessons ? getRoutePath("lessons", { courseId: `${selectedCourse.id}` }) : ""}
                        className={classNames("rounded-md text-sm font-light p-2", {
                          "hover:bg-green-200": selectedCourse.hasLessons,
                          "hover:cursor-not-allowed text-gray-300": !selectedCourse.hasLessons,
                          "text-green-torcal ": matchRoutes([{ path: routesPath["lessons"] }, { path: routesPath["lesson"] }], location)?.length,
                        })}
                      >
                        {t("Header.lessons")}
                      </Link>
                      <Menu as="div" className="ml-3 relative z-20">
                        {({ open }) => (
                          <>
                            <Menu.Button
                              className={classNames(
                                "max-w-xs text-sm bg-white font-light inline-flex items-center justify-center p-2 rounded-md hover:bg-green-200",
                                {
                                  "bg-green-200": open,
                                  "text-green-torcal ": matchRoutes(
                                    [
                                      {
                                        path: routesPath["tests.subject"],
                                      },
                                      {
                                        path: routesPath["tests.subject.review"],
                                      },
                                      {
                                        path: routesPath["tests.exam"],
                                      },
                                      {
                                        path: routesPath["tests.revision"],
                                      },
                                    ],
                                    location
                                  )?.length,
                                }
                              )}
                            >
                              {t("Header.tests")}
                            </Menu.Button>
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item key="temas">
                                <Link
                                  to={getRoutePath("tests.subject", {
                                    courseId: `${selectedCourse.id}`,
                                  })}
                                  className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                                    "text-green-torcal ": matchRoutes(
                                      [
                                        {
                                          path: routesPath["tests.subject"],
                                        },
                                        {
                                          path: routesPath["tests.subject.review"],
                                        },
                                      ],
                                      location
                                    )?.length,
                                  })}
                                >
                                  <span className="text-md font-light">{t("Header.Tests.topic")}</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="examen">
                                <Link
                                  to={getRoutePath("tests.exam", {
                                    courseId: `${selectedCourse.id}`,
                                  })}
                                  className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                                    "text-green-torcal ": matchRoutes(
                                      [
                                        {
                                          path: routesPath["tests.exam"],
                                        },
                                      ],
                                      location
                                    )?.length,
                                  })}
                                >
                                  <span className="text-md font-light">{t("Header.Tests.exam")}</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="repaso">
                                <Link
                                  to={
                                    isReviewTestEnabled
                                      ? getRoutePath("tests.revision", {
                                          courseId: `${selectedCourse.id}`,
                                        })
                                      : ""
                                  }
                                  className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                                    "text-gray-300": !isReviewTestEnabled,
                                    "text-green-torcal": matchRoutes(
                                      [
                                        {
                                          path: routesPath["tests.revision"],
                                        },
                                      ],
                                      location
                                    )?.length,
                                  })}
                                >
                                  <span className="text-md font-light">{t("Header.Tests.revision")}</span>
                                </Link>
                              </Menu.Item>
                            </Menu.Items>
                          </>
                        )}
                      </Menu>
                      {sessionId ? (
                        <Link
                          key="classroom"
                          to={getRoutePath("lesson-meeting", {
                            courseId: selectedCourse?.id.toString() ?? "",
                            lessonId: sessionId?.toString(),
                          })}
                          className={classNames("rounded-md text-sm font-light hover:bg-green-200 p-2", {
                            "text-green-torcal ": matchRoutes(
                              [
                                {
                                  path: routesPath["lesson"],
                                },
                              ],
                              location
                            )?.length,
                          })}
                        >
                          {t("Header.yourclass")}
                        </Link>
                      ) : (
                        <Link
                          key="classroom"
                          to={getRoutePath("classroom", {
                            courseId: `${selectedCourse.id}`,
                          })}
                          className={classNames("rounded-md text-sm font-light hover:bg-green-200 p-2", {
                            "text-green-torcal ": matchRoutes(
                              [
                                {
                                  path: routesPath["classroom"],
                                },
                              ],
                              location
                            )?.length,
                          })}
                        >
                          {t("Header.classes")}
                        </Link>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <Menu.Button className="max-w-xs text-sm bg-white inline-flex items-center justify-center p-2 rounded-md text-green-torcal hover:bg-green-200">
                          <span className="sr-only">{t("Header.open-main-menu")}</span>
                          <span className="mr-2">{username}</span>
                          <UserIcon className="block h-6 w-6" aria-hidden="true" />
                        </Menu.Button>
                        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item key="changeCourse">
                            <Link to={getRoutePath("courses")} className="px-4 py-2 w-full flex hover:bg-gray-100">
                              <AcademicCapIcon className="h-6 w-6 text-green-torcal mr-4 stroke-1" />
                              <span className="text-sm font-light">{t("Header.change-course")}</span>
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="logout">
                            <button type="button" onClick={logoutAction} className="px-4 py-2 w-full flex hover:bg-gray-100">
                              <PowerIcon className="h-6 w-6 text-green-torcal mr-4 stroke-1" />
                              <span className="text-sm font-light">{t("Header.logout")}</span>
                            </button>
                          </Menu.Item>
                        </Menu.Items>
                      </>
                    )}
                  </Menu>
                </div>
              </div>

              {/* Mobile menu button */}
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="max-w-xs text-sm bg-white inline-flex items-center justify-center p-2 rounded-md text-green-torcal hover:bg-green-200">
                  <span className="sr-only">{t("Header.open-main-menu")}</span>
                  {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars4Icon className="block h-6 w-6" aria-hidden="true" />}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {selectedCourse ? (
                <>
                  <Disclosure.Panel
                    key="course"
                    className={classNames("hover:bg-white hover:text-green-800 bg-white text-green-torcal block px-3 py-2 rounded-md text-base font-medium")}
                  >
                    <Link
                      onClick={() => {
                        close();
                      }}
                      to={getRoutePath("course", {
                        courseId: `${selectedCourse.id}`,
                      })}
                    >
                      {t("Header.home")}
                    </Link>
                  </Disclosure.Panel>
                  <Disclosure.Panel
                    key="lessons"
                    className={classNames("hover:bg-white hover:text-green-800 bg-white text-green-torcal block px-3 py-2 rounded-md text-base font-medium")}
                  >
                    <Link
                      onClick={() => {
                        close();
                      }}
                      to={getRoutePath("lessons", {
                        courseId: `${selectedCourse.id}`,
                      })}
                    >
                      {t("Header.lessons")}
                    </Link>
                  </Disclosure.Panel>
                  <Disclosure.Panel
                    key="test"
                    as="button"
                    className={classNames("hover:bg-white hover:text-green-800 bg-white text-green-torcal block px-3 py-2 rounded-md text-base font-medium")}
                  >
                    <>
                      <p className="text-left">{t("Header.tests")}</p>
                      <Link
                        onClick={() => {
                          close();
                        }}
                        key="subject"
                        to={getRoutePath("tests.subject", {
                          courseId: `${selectedCourse.id}`,
                        })}
                        className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                          "text-green-torcal ": matchRoutes(
                            [
                              {
                                path: routesPath["tests.subject"],
                              },
                              {
                                path: routesPath["tests.subject.review"],
                              },
                            ],
                            location
                          )?.length,
                        })}
                      >
                        <span className="text-md font-light">{t("Header.Tests.topic")}</span>
                      </Link>
                      <Link
                        onClick={() => {
                          close();
                        }}
                        key="examen"
                        to={getRoutePath("tests.exam", {
                          courseId: `${selectedCourse.id}`,
                        })}
                        className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                          "text-green-torcal ": matchRoutes(
                            [
                              {
                                path: routesPath["tests.exam"],
                              },
                            ],
                            location
                          )?.length,
                        })}
                      >
                        <span className="text-md font-light">{t("Header.Tests.exam")}</span>
                      </Link>
                      <Link
                        onClick={() => {
                          close();
                        }}
                        key="repaso"
                        to={
                          isReviewTestEnabled
                            ? getRoutePath("tests.revision", {
                                courseId: `${selectedCourse.id}`,
                              })
                            : ""
                        }
                        className={classNames("px-4 py-2 w-full flex hover:bg-gray-100", {
                          "text-gray-300": !isReviewTestEnabled,
                          "text-green-torcal": matchRoutes(
                            [
                              {
                                path: routesPath["tests.revision"],
                              },
                            ],
                            location
                          )?.length,
                        })}
                      >
                        <span className="text-md font-light">{t("Header.Tests.revision")}</span>
                      </Link>
                    </>
                  </Disclosure.Panel>
                  <Disclosure.Panel
                    key="classroom"
                    className={classNames("hover:bg-white hover:text-green-800 bg-white text-green-torcal block px-3 py-2 rounded-md text-base font-medium")}
                  >
                    <Link
                      onClick={() => {
                        close();
                      }}
                      to={getRoutePath("classroom", {
                        courseId: `${selectedCourse.id}`,
                      })}
                    >
                      {t("Header.classes")}
                    </Link>
                  </Disclosure.Panel>
                  <Disclosure.Panel
                    key="change-course"
                    className={classNames("hover:bg-white hover:text-green-800 bg-white text-green-torcal block px-3 py-2 rounded-md text-base font-medium")}
                  >
                    <Link
                      onClick={() => {
                        close();
                      }}
                      to={getRoutePath("courses", {
                        courseId: `${selectedCourse.id}`,
                      })}
                    >
                      {t("Header.change-course")}
                    </Link>
                  </Disclosure.Panel>
                </>
              ) : null}
            </div>
            <div className="pb-3 border-t border-gray-200">
              <div className="mt-3 px-2 space-y-1">
                <Disclosure.Button
                  key="logout"
                  as="button"
                  onClick={logoutAction}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  {t("Header.logout")}
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
