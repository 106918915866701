import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VideoRoom } from "./VideoCall/VideoRoom";
import { useChat } from "../../hooks/useChat";

interface VideoConfig {
  hasVideo: boolean;
  token?: string;
  roomName?: string;
}

export default function BubbleVideoCall({ videoConfigOld }: { videoConfigOld: VideoConfig }) {
  const { t } = useTranslation();
  const [showVideoCall, setShowVideoCall] = useState<boolean>(false);
  const { setHasVideo, videoConfig } = useChat(true);

  if (!videoConfig) {
    return null;
  }

  const pulseStyle = {
    animation: "pulse 1s infinite",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    boxShadow: "0 0 0 0 rgba(240, 126, 21, 0.7)",
  };

  const pulseKeyframes = `
     @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(240, 126, 21, 0.7);
      }
      70% {
        box-shadow: 0 0 0 10px rgba(240, 126, 21, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(240, 126, 21, 0);
      }
    }
  `;

  return (
    <>
      <style>{pulseKeyframes}</style>

      {videoConfig.hasVideo && videoConfig.roomName && videoConfig.token && showVideoCall ? (
        <VideoRoom
          onCloseVideo={() => {
            setShowVideoCall(false);
            setHasVideo(false);
          }}
          roomName={videoConfig.roomName}
          token={videoConfig.token}
        />
      ) : null}

      {videoConfig.hasVideo ? (
        <button
          onClick={() => {
            setShowVideoCall(true);
          }}
          className="relative bg-gray-100 flex flex-col w-full p-2 rounded-xl"
          style={pulseStyle}
        >
          <p className="text-left text-xs font-bold pulse">{t("Chat.callIncoming")}</p>
        </button>
      ) : null}
    </>
  );
}
