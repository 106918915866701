import { BAD_CREDENTIALS, DEFAULT_ERROR_MESSAGE, SEND_MAIL_ERROR } from "../constant/ErrorMessages";
import { RefreshToken as RefreshTokenI, User } from "../interfaces/user";
import { CustomError } from "../utils/CustomError";
import { apiFetch, refreshToken as refreshTokenAPI } from "./api";
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export interface IStudentData {
  email: string;
  firstname: string;
  id: string;
  lastname1: string;
  lastname2: string;
  login: string;
}

export async function login(username: string, password: string): Promise<User> {
  try {
    const request = await fetch(`${baseUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `${encodeURIComponent("username")}=${encodeURIComponent(username)}&${encodeURIComponent("password")}=${encodeURIComponent(password)}`,
    });

    if (request.status === 403) {
      throw new CustomError(BAD_CREDENTIALS, 401);
    }
    const response: User = await request.json();
    response.username = username;
    return response;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function logout(refreshToken: string): Promise<void> {
  await apiFetch(`/auth/logout?refreshToken=${refreshToken}`, "PUT");
}

export async function getUser(): Promise<IStudentData> {
  const studentData = await apiFetch<{ student: IStudentData }>(`/student`, "GET");
  localStorage.setItem("studentData", JSON.stringify(studentData.student));
  return studentData.student;
}

export async function forgotPassword(username: string): Promise<void> {
  try {
    const request = await fetch(`${baseUrl}/auth/request_reset/${username}`, {
      method: "PUT",
    });

    if (!request.ok && request.status !== 404) {
      throw new CustomError(SEND_MAIL_ERROR);
    }
  } catch (error) {
    throw new CustomError(SEND_MAIL_ERROR);
  }
}

export async function refreshToken(): Promise<RefreshTokenI> {
  return refreshTokenAPI();
}
