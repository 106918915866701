import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChat } from "../../hooks/useChat";
import { useUser } from "../../hooks/useUser";
import { ChatMessage } from "../../interfaces/chat";
import Spinner from "../Layout/Spinner";
import BubbleReply from "./BubbleReply";
import BubbleVideoCall from "./BubbleVideoCall";
import ChatIcon from "./ChatIcon";
import Message from "./Message";
import SendMessage from "./SendMessage";

export default function Chat() {
  const { t } = useTranslation();
  const { user } = useUser();
  const messagesContainer = useRef<HTMLHeadingElement>(null);
  const chatInput = useRef<HTMLInputElement>(null);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [totalMessages, setTotalMessages] = useState<number>(0);
  const [replyTo, setReplyTo] = useState<ChatMessage | undefined>();
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const { videoConfig, isAvailableChat, isLoadingMessages, userMessages, sendMessage, getMessage } = useChat(true);

  const scrollToLastMessage = () => {
    setTimeout(() => {
      if (messagesContainer.current) {
        messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
        chatInput?.current?.focus();
      }
    }, 100);
  };

  useEffect(() => {
    if (!showChat && userMessages && userMessages.length > totalMessages) {
      setTotalMessages(userMessages.length);
      setShowChat(true);
    }
    scrollToLastMessage();
  }, [userMessages, showChat]);

  const submitMessage = async (text: string) => {
    setIsSendingMessage(true);
    await sendMessage(text, replyTo);
    setIsSendingMessage(false);
    setReplyTo(undefined);
  };

  if (!isAvailableChat) {
    return null;
  }

  return (
    <>
      <ChatIcon
        showChat={showChat}
        onOpenChat={() => {
          setShowChat(!showChat);
        }}
      />

      <div className={classNames(showChat ? "fixed z-50 bottom-10 right-8 w-full sm:w-96 bg-gray-200" : "hidden")}>
        <div className="flex items-center justify-end rounded-t-lg bg-orange-600 p-2">
          <button onClick={() => setShowChat(!showChat)} className="text-white">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="py-2 px-4 h-[500px] flex flex-col">
          {isLoadingMessages || !user ? (
            <div className="flex flex-col items-center justify-center h-full space-y-5">
              <Spinner className="h-20" lineColor="text-orange-torcal" />
              <p>{t("Chat.loading-messages")}</p>
            </div>
          ) : (
            <div className="h-full overflow-scroll pb-5 flex flex-col" ref={messagesContainer}>
              {userMessages
                ?.filter((f) => f.attributes.msgType !== "calling")
                .map((m) => (
                  <Message
                    key={m.sid}
                    userName={user.name}
                    message={m}
                    parentMessage={getMessage(m.attributes.parentMsgSid)}
                    onRespond={(replyTo) => {
                      setReplyTo(replyTo);
                      chatInput?.current?.focus();
                    }}
                  />
                ))}
            </div>
          )}

          <div className="mt-auto flex flex-col justify-start">
            <BubbleVideoCall videoConfigOld={videoConfig} />

            <BubbleReply
              replyTo={replyTo}
              onCancelReply={() => {
                setReplyTo(undefined);
              }}
            />

            <SendMessage isLoading={isSendingMessage} submitMessage={submitMessage} />
          </div>
        </div>
      </div>
    </>
  );
}
