/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { login as loginAPI, logout as logoutAPI, refreshToken } from "../api/user";
import { BAD_CREDENTIALS, LOGIN_ERROR, LOGOUT_ERROR } from "../constant/ErrorMessages";
import { userState } from "../state/user";
import { CustomError } from "../utils/CustomError";
import { cleanUserInfo, getUserInfo, saveUserInfo } from "../utils/sessionStorage";
import { IUserMetadata } from "../interfaces/user";
import { getPreffixFirebase } from "../api/api";
import { ref, set, update } from "firebase/database";
import { getFirebaseDatabase } from "../utils/firebase";
import { useCourses } from "./useCourses";

export function useUser() {
  const [user, setUser] = useRecoilState(userState);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { selectedCourse } = useCourses();
  const isAuthenticated = user !== undefined;

  const checkInitialStatus = useCallback(async () => {
    try {
      const userData = getUserInfo();
      if (userData) {
        const newTokenResponse = await refreshToken();
        setUser({
          ...userData,
          token: newTokenResponse.token,
          refreshToken: newTokenResponse.refreshToken,
        });
      }
    } catch (error) {
      setUser(undefined);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
      return;
    }
    checkInitialStatus();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    setIsLoading(true);
    setError(undefined);
    try {
      const user = await loginAPI(username, password);
      const studentData = await getUserInfo();
      saveUserInfo({ ...user, ...studentData });
      setUser(user);
      window.localStorage.setItem("username", username.trim());
    } catch (error) {
      if (error instanceof CustomError) {
        setError(BAD_CREDENTIALS);
      } else {
        setError(LOGIN_ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    setError(undefined);
    if (user) {
      try {
        await logoutAPI(user?.refreshToken);
        cleanUserInfo();
        setUser(undefined);
        window.localStorage.clear();
        sessionStorage.clear();
      } catch (error) {
        setError(LOGOUT_ERROR);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const setUserMetadata = async (metadata: IUserMetadata) => {
    if (selectedCourse?.id) {
      const apiCall = await fetch("https://api.ipify.org?format=json");
      const publicIp = await apiCall.json();
      const username = window.localStorage.getItem("username")?.replace(/\./g, "_POINT_");
      const studentData = JSON.parse(localStorage.getItem("studentData") || "{}");

      metadata.ip = metadata.ip || publicIp.ip;
      metadata.browser = navigator.userAgent;
      metadata.userName = username;
      metadata.name = `${user?.name} ${studentData.lastname1} ${studentData.lastname2}`;

      const metadataPath = `onlineUsers${getPreffixFirebase()}/${selectedCourse.id}/${username}`;

      // firebase is not allowing undefined, change them with null
      metadata.currentTest?.testDetails?.questions.forEach((question) => {
        question.answeredByUser = question.answeredByUser ?? null;
      });
      update(ref(getFirebaseDatabase(), metadataPath), { ...metadata, date: new Date().toISOString() });
    }
  };

  const setUserMetadataCurrentQuestion = async (question: string) => {
    if (selectedCourse?.id) {
      const username = window.localStorage.getItem("username")?.replace(/\./g, "_POINT_");

      // TODO update date path:
      const datePath = `onlineUsers${getPreffixFirebase()}/${selectedCourse.id}/${username}/date`;
      set(ref(getFirebaseDatabase(), datePath), new Date().toISOString());

      const currentTestPath = `onlineUsers${getPreffixFirebase()}/${selectedCourse.id}/${username}/currentTest`;
      update(ref(getFirebaseDatabase(), currentTestPath), { question });
    }
  };

  return {
    isLoading,
    isAuthenticated,
    user,
    error,
    login,
    logout,
    setUserMetadata,
    setUserMetadataCurrentQuestion,
  };
}
